import { Injectable } from "@angular/core";
import { LOCATIONS_CONSTANTS } from "../interfaces/locations-constants";
import * as L from "leaflet";
import { ELOCATION_SCREENS } from '../interfaces/search-location.interface';
import { Subject } from 'rxjs';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';

@Injectable({
  providedIn: 'root',
})

export class CreateMapService {
  constructor(private _storeManagerService: StoreManagerService) {
    this.getUserSelectedMapTheme();
    this.getCurrentMapData();
  }

  // Variable declarations start here
  private destroy$: Subject<boolean> = new Subject<boolean>();
  mapZoom: number = LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC;
  private map: any;
  coordinates: any;
  initialCoordinates: any = {
    coords: {
      latitude: 52.520007,
      longitude: 13.404954
    }
  };
  public selectedLayer: string = 'normal';
  public normalView: L.TileLayer = new L.TileLayer('', {});
  public satelliteView: L.TileLayer = new L.TileLayer('', {});
  // this will be true for 1st load and then we will reset to false
  public isInitialLoad: boolean = true;
  public _currentMapDetails: { latLng: string; zoom: number; } = {latLng:"52.520007,13.404954", zoom:LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR  };
  
  /**
   * @description This method is used to get the user location
   * If user has given permission to access location then it will return the coordinates
   * else it will return the default coordinates
   * @returns cooredinates
   */
  async getUserLocation(): Promise<string[]> {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

      if (permissionStatus.state === 'denied') {
        throw new Error(permissionStatus.state);
      }
      return new Promise<string[]>((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(this.panningMapLocation());
          return;
        }
    
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(this.panningMapLocation(position));
        }, error => {
          reject(this.panningMapLocation());
        });
      });
    } catch (error) {
      return Promise.reject(false);
    }
    
  }

  public panningMapLocation(position?: any): any {
   let  coordinates = (position) ? [position?.coords?.latitude, position?.coords?.longitude] : [this.initialCoordinates?.coords?.latitude, this.initialCoordinates?.coords?.longitude];
   return coordinates;
  }

  public loadMap(apiKey: string, from: string): any {
    const userSelectedLanguage = this._storeManagerService.getUserSelectedLanguageFromStore();
    const mapLanguage = userSelectedLanguage.split('-')[0] || 'en';
    let zoom;   
    const getZoom = () => {
      if (from === ELOCATION_SCREENS.EMOTION_TOUR_INFO) {
        zoom = this._currentMapDetails.zoom;
      } else if (sessionStorage.getItem('mapCoords')) {
        zoom = this._currentMapDetails.zoom || LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC;
      } else {
        zoom = this._currentMapDetails.zoom || LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_NO_LOC;
      } 
      return zoom;
    }
    const latLng = this.getLatLngBasedOnPage(from)
    const lat = latLng.lat;
    const lon = latLng.lon;
    this.normalView = L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?lang=${mapLanguage}&lang2=en&apiKey=${apiKey}`, {
      maxZoom: LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR,
    });
    this.satelliteView = L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?style=satellite.day&apiKey=${apiKey}`, {
      maxZoom: LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR,
    });
    const mapData = {
      center: [lat, lon],
      zoom: getZoom(),
      minZoom: 2,
      zoomSnap: 0.1,
      zoomControl: false,
      scrollWheelZoom: "center",
      attributionControl: false,
      worldCopyJump: true,
      maxBounds: new L.LatLngBounds([
        [-90, -180],
        [90, 180],
      ]),
      layers: (this.selectedLayer === 'satellite') ? [this.satelliteView] : [this.normalView],
    };
    return mapData;
  }

  public getLatLngBasedOnPage(from: string): {lat: string, lon: string} {
    this.getCurrentMapData();
    switch(from) {
      case ELOCATION_SCREENS.EMOTION_TOUR_INFO:
      case ELOCATION_SCREENS.TRACK_INFO:
      case ELOCATION_SCREENS.LANDING_PAGE:
        return {
          lat: this._currentMapDetails.latLng?.split(',')[0] || sessionStorage.getItem('mapCoords')?.split(',')[0] || this.initialCoordinates?.coords?.latitude,
          lon: this._currentMapDetails.latLng?.split(',')[1] || sessionStorage.getItem('mapCoords')?.split(',')[1] || this.initialCoordinates?.coords?.longitude
        }
      default:
        return {
          lat: this.initialCoordinates?.coords?.latitude,
          lon: this.initialCoordinates?.coords?.longitude
        };
    }
  } 

  public getBaseMapDetails(): any {
    const baseMaps = {
      "Satellite": this.satelliteView,
      "Normal": this.normalView
    };
    return baseMaps
  }

  /**
   * @description This is called when the application is loaded.
   * This is to initialize all the data when the map component loads
   */
  public getUserSelectedMapTheme() {
    this.selectedLayer = this._storeManagerService.getMapThemeFromStore();
  }

  public getCurrentMapData(): void {
    let mapDataFromStore = this._storeManagerService.getCurrentMapDataFromStore();
    if (this.isInitialLoad) {
      mapDataFromStore = this._storeManagerService.getMapDataOnLoad();
    }
    this._currentMapDetails.latLng = mapDataFromStore?.latLng;
    this._currentMapDetails.zoom = mapDataFromStore?.zoom;
  }
}
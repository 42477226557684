import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../../services/locations-http.service";
import { AlertModalService } from "../../../services/modal/alert-modal.service";
import { DecimalPipe, Location } from "@angular/common";
import { MediaCarouselService } from "../../../services/media/media-carousel.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { LanguageService } from "src/app/shared/service/language.service";
import { AppConstants } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../../interfaces/locations-constants";
@Component({
  selector: "app-layout-details-form",
  templateUrl: "./layout-details-form.component.html",
  styleUrls: ["./layout-details-form.component.scss"],
})
export class LayoutDetailsFormComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() formData: any;
  @Input() formEditable: any;
  @Output() updatedTrackDetails = new EventEmitter<any>();
  public layoutData: any;
  form!: UntypedFormGroup;
  public typeOptions: any;
  public saveData: any;
  public mediaFiles: any;
  public subscribeFormData: any;
  public subscribeMediaData: any;
  public layoutURL: any;
  public mediaUrls: any = [];
  guidValue: any;
  private trackDetails: any = {};
  public trackLength = 0;
  public imageUrls: any[] = [];
  videoUrl: any = "https://www.bigbuckbunny.org/";
  encodedTrackPoints: any;
  trackName: any;
  public count: any = 0;
  public wordCount = 0;
  hasChange: boolean = false;
  @Input("isFormDataReverted") isFormDataReverted = false;
  @Output() setUpdateFlagValue = new EventEmitter<any>();
  @Output() isFormValid = new EventEmitter<boolean>(true);
  translationData: any;
  language: string = ''
  appConstants = AppConstants;
  locationConstants = LOCATIONS_CONSTANTS
  showLayoutUrl: boolean = false;
  @Input() showCopyrightData: boolean = false;
  @Input() deletedImageIds: string[] = [];
  urlRegex = LOCATIONS_CONSTANTS.URL_VALIDATION_REGEX_PATTERN;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private locationsHttpService: LocationsHttpService,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private _decimalPipe: DecimalPipe,
    private location: Location,
    private mediaCarouselService: MediaCarouselService,
    private translationService: TranslationService,
    private languageService: LanguageService
  ) {
    this.getMedia();
  }

  public ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
        this.showLayoutUrl = this.translationService.featureFlags?.locations.showLayoutUrl;
      }
    );
    
    this.initializeTrackDetails();
    this.subscribeFormData = this.locationsHttpService.shareformData.subscribe(
      (data) => {
        this.saveData = data;
        if (this.saveData == "publish") {
          this.saveAndPublish();
        } else if (this.saveData === "draft") {
          this.saveAsDraft();
        } else {
          this.updateDetails();
        }
      }
    );
  }

  public revertFormData(): void {
    this.getTrackDetails(this.formData.guid);
    this.form.patchValue({
      name: [this.layoutData.name],
      description: [this.layoutData.description],
      createdDate: [this.layoutData.createdDate],
      visibility: [this.layoutData.visibility],
      noOfSectors: [this.layoutData.noOfSectors],
      guid: [this.layoutData.guid],
      ciamId: [this.layoutData.ciamid],
      trackLength: [this.layoutData.trackLength],
      copyright: this.formBuilder.group({
        attribution: this.layoutData.media?.image[0]?.copyright?.attribution,
        license: this.layoutData.media?.image[0]?.copyright?.license,
        license_url: [this.layoutData.media?.image[0]?.license_url, [Validators.pattern(this.urlRegex)]],
        subject_url: [this.layoutData.media?.image[0]?.subject_url, [Validators.pattern(this.urlRegex)]],
        complete: this.layoutData.media?.image[0]?.copyright?.attribution || this.layoutData.media?.image[0]?.copyright?.license
          && this.layoutData.media?.image[0]?.license_url || this.layoutData.media?.image[0]?.subject_url ? true: false
      })
    });
    if (this.layoutData.visibility.toUpperCase() == "DRAFT") {
      this.layoutData.visibility = "PERMANENT";
    }
    this.wordCount = this.form.value.description.length;
    this.form.get("visibility")?.patchValue(this.layoutData.visibility);
    this.locationsHttpService.clearMediaValue();
    this.mediaFiles = [];
    this.isFormDataReverted = false;
  }

  ngOnChanges() {
    this.checkFormStatus();
    if (this.isFormDataReverted) {
      this.revertFormData();
    }
  }

  ngAfterContentChecked() {
    this.textAreaAdjust();
  }

  public checkFormStatus(): void {
    if (this.form) {
      if (this.formEditable) {
        this.form.enable();
        this.form.valueChanges.subscribe((_value) => {
          if (!this.hasChange) {
            this.setUpdateFlagValue.emit({ enableUpdateButton: true });
          }
          this.isFormValid.next(this.form.valid)
        });
      } 
    }
  }

  public getMedia(): void {
    this.mediaFiles = [];
    this.subscribeMediaData =
      this.locationsHttpService.sharemediaData.subscribe((data) => {
        if (data) {
          this.mediaFiles = data[0];
        }
      });
  }
  
  /**
   * 
   * @param formGroup 
   * @returns form values that are dirty 
   */
  public getDirtyValues(formGroup: UntypedFormGroup): any {
    const dirtyValues: any = {};
    Object.keys(formGroup.controls).forEach((key) => {
      const currentControl = formGroup.controls[key];
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl instanceof UntypedFormGroup
          ? this.getDirtyValues(currentControl)
          : currentControl.value;
      }
    });
    return dirtyValues;
  }
  
  /**
   * @description Checks if the form is valid
   * @returns boolean
   */
  public checkFormValidity(): boolean {
    let isFormValid = true;
    this.alertModalService.spinnerActiveValue.next(false);
    if (this.form.invalid) {
      isFormValid = false;
      this.toastService.show(
        ToastType.Error,
        'Please verify the form details',
        "8%",
        "41%"
      );
      setTimeout(() => {
        this.toastService.hide();
      }, LOCATIONS_CONSTANTS.MAX_TIME);
    }
    return isFormValid;
  }

  /**
   * @description Saves and publishes the form
   * @returns void
   */
  public saveAndPublish(): void {
    if (!this.checkFormValidity()) {
      return;
    }
    if (this.form.controls["visibility"].value == "DRAFT") {
      this.form.controls["visibility"].setValue("PERMANENT");
    }
    let editedValues = this.getDirtyValues(this.form);
    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }

  /**
   * @description Saves the form as draft
   * @returns void
   */
  public saveAsDraft(): void {
    if (!this.checkFormValidity()) {
      return;
    }
    this.form.controls["visibility"].setValue("DRAFT");
    let editedValues = this.getDirtyValues(this.form);
    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }

  public updateDetails(): void {
    if (!this.checkFormValidity()) {
      return;
    }

    const editedValues = this.getDirtyValues(this.form);
    this.flattenArrayFields(editedValues, ['guid', 'name', 'description']);
    this.guidValue = editedValues.guid;

    if (this.isNameUnchanged(editedValues.name)) {
      delete editedValues.name;
    }

    this.getMedia();
    this.updateTrack(editedValues, this.mediaFiles);
  }

  public flattenArrayFields(obj: any, fields: string[]): void {
    fields.forEach(field => {
      if (Array.isArray(obj[field])) {
        obj[field] = obj[field].join();
      }
    });
  }

  public isNameUnchanged(newName: string): boolean {
    return this.layoutData?.name?.toLowerCase() === newName?.toLowerCase();
  }

  /**
   * @function getCircuitData
   * @description called on clicking `Update` from header, post update service is successful
   * @returns void
   */
  public getCircuitData(): void {
    this.locationsHttpService
      .getCircuit(this.layoutData.circuitGuid)
      .subscribe((data: any) => {
        let newCircuitData: any = data.body;
        newCircuitData.guid = this.layoutData.circuitGuid;
        this.locationsHttpService.getLatestCircuitDetails(newCircuitData);
        if (this.saveData != "") {
          this.router.navigateByUrl(
            `/locations/circuit/${newCircuitData.guid}`
          );
        } else {
          this.getTrackDetails(this.guidValue);
          if (this.layoutData.visibility.toUpperCase() == "DRAFT") {
            this.layoutData.visibility = "PERMANENT";
          }
        }
      });
  }

  /**
   * @function initializeTrackDetails
   * @returns void
   * @description called on load - ngOnInit to initialize form details
   */
  public initializeTrackDetails(guid?: string): void {
    this.locationsHttpService.getTrackDetails(this.formData.guid).subscribe((trackData) => {
        this.trackDetails = trackData.body;
        this.setValuesForTrackData();
    });
  }

  public checkWordCount(): void {
    this.wordCount = this.form.value.description.length;
  }

  /**
   * @function setValuesForTrackData
   * @param guid : string
   * @returns void
   * @description called from `initializeTrackDetails` and `getTrackDetails` - sets form data - circuit details, name, type...
   */
  private setValuesForTrackData(guid?: string): void {
    this.trackDetails.ciamid = this.trackDetails.owner?.ciamId;
    this.trackDetails.guid = guid || this.formData.guid;
    this.locationsHttpService.getLatestTrackDetails(this.trackDetails);

    this.encodedTrackPoints = this.trackDetails.track
      ? this.trackDetails.track.geometry.coordinates
      : this.trackDetails.encodedGpsPoints;

    if (!guid) {
      this.layoutURL = `${environment.routeBaseURL}/${this.language}/explore/circuit/${this.formData.name}-${this.formData.guid}`;
    } else {
      this.populateMediaUrls();
      this.layoutData = this.trackDetails;
    }

    this.layoutData = this.trackDetails;
    this.layoutData.trackLength = `${this._decimalPipe.transform(this.layoutData.trackLength / 1000, "1.2-2")} km`;
    this.layoutData.createdDate = new Date(this.layoutData.createdDate?.replace(/\s/, "T")).toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    if (this.layoutData.visibility.toUpperCase() === "DRAFT") {
      this.layoutData.visibility = "PERMANENT";
    }

    this.layoutData.createdBy = this.trackDetails.owner?.nickname || this.trackDetails.owner?.name || this.trackDetails.owner?.ciamId;
    this.showCopyrightData = this.layoutData.media?.image.length > 0;

    this.initializeForm();
    this.typeOptions = [
      { name: "Permanent", id: "PERMANENT" },
      { name: "Temporary/ one time", id: "TEMPORARY" },
      { name: "Closed", id: "CLOSED" },
    ];
    this.wordCount = this.form.value.description.length;
    this.checkFormStatus();
  }

  private populateMediaUrls(): void {
    this.trackDetails.media?.image?.forEach((image: any, index: number) => {
      this.mediaUrls.push({
        originalUrl: image.originalUrl,
        thumbnailUrl: image.thumbnailUrl,
        type: "image",
        count: index,
        id: image.id,
        guid: this.trackDetails.guid,
      });
      this.count = index + 1;
    });

    // Commenting this code for now as we are not supporting videos
    /*this.trackDetails.media?.video?.forEach((video: any, index: number) => {
      this.mediaUrls.push({
        originalUrl: video.originalUrl,
        thumbnailUrl: video.thumbnailUrl,
        type: "video",
        count: this.count + index,
        id: video.id,
        guid: this.trackDetails.guid,
      });
    }); */

    this.mediaCarouselService.shareMediaUrls.next(this.mediaUrls);
  }

  public initializeForm(): void {
    this.form = this.formBuilder.group({
      name: [this.layoutData.name],
      description: [this.layoutData.description],
      createdDate: [this.layoutData.createdDate],
      visibility: [this.layoutData.visibility],
      noOfSectors: [this.layoutData.noOfSectors],
      guid: [this.layoutData.guid],
      ciamId: [this.layoutData.ciamid],
      trackLength: [this.layoutData.trackLength],
      createdBy: [this.layoutData.createdBy],
      copyright: this.formBuilder.group({
        attribution: this.layoutData.media?.image[0]?.copyright?.attribution,
        license: this.layoutData.media?.image[0]?.copyright?.license,
        license_url: [this.layoutData.media?.image[0]?.copyright?.license_url, [Validators.pattern(this.urlRegex)]],
        subject_url: [this.layoutData.media?.image[0]?.subject_url, [Validators.pattern(this.urlRegex)]],
      }),
    });
  }

 
  /**
   * @function getTrackDetails
   * @param guid - string
   * @description called when we Cancel and click Yes from Cancel Popup
   * Called post update is complete - an details in circuit is updated
   */
  public getTrackDetails(guid: string): void {
      this.locationsHttpService.getTrackDetails(guid).subscribe((trackData) => {
        this.imageUrls = [];
        this.mediaUrls = [];
        this.trackDetails = trackData.body;
        this.updatedTrackDetails.emit(this.trackDetails);
        this.showCopyrightData = trackData?.media?.image?.length;
        this.setValuesForTrackData(guid);
        
      });  
  }

  /**
   * @description 
   * @param data 
   * @param media 
   */
  public updateTrack(data: any, media: any): void {
    const userId = "Admin";
    this.buildRequestForUpdate(data);

    this.alertModalService.spinnerActiveValue.next(true);
    this.locationsHttpService.UpdateTrackDetails(userId, data, media).subscribe({
      next: (res: any) => this.handleUpdateSuccess(res),
      error: (error: any) => this.handleUpdateError(error),
    });
  }

  /**
   * @description Added this function to form the request for update API
   * Segregated the logic to build the request for update
   * @param data 
   */
  public buildRequestForUpdate(data: any): void {
    if (this.imageUrls.length > 0) {
      this.form.controls.copyright.reset();
      if (this.hasCopyrightData()) {
        data.copyright = this.form.controls.copyright.value;
      } else {
        this.form.removeControl('copyright');
      }
    }
    data.guid = this.form.value.guid;
    data.visibility = this.form.value.visibility;
    if (this.deletedImageIds.length > 0) {
      data.deleteMediaIds = this.deletedImageIds;
    }
  }

  public hasCopyrightData(): boolean {
    const copyright = this.form.controls.copyright.value;
    return copyright ? copyright.license || copyright.license_url || copyright.subject_url: false;
  }

/**
 * @description handle success response of update API
 * @param res 
 */
  public handleUpdateSuccess(res: any): void {
    if (res.body.message) {
      this.getCircuitData();
      this.getTrackDetails(this.formData.guid);
      this.router.navigateByUrl(`/locations/circuit/${this.layoutData.circuitGuid}`);
      this.alertModalService.spinnerActiveValue.next(false);
      this.showToast(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_UPDATE_SUCCESS_MSG);
    }
  }

  /**
   * @description handle error response of update API
   * @param error 
   */
  public handleUpdateError(error: any): void {
    if (error.status === 0) {
      this.showToast(ToastType.Error, "Cannot upload file as media length exceeds");
    } else if (error.error["error-code"] === 1434) {
      this.showToast(ToastType.Error, error.error["error-message"]);
      this.form.get("name")?.patchValue(this.layoutData.name);
    } else {
      this.showToast(ToastType.Error, error.error["error-message"]);
    }
    this.alertModalService.onAction.next(null);
    this.alertModalService.spinnerActiveValue.next(false);
  }

  public showToast(type: ToastType, message: string): void {
    this.toastService.show(type, message, "8%", "41%");
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }

  public openLayoutUrl(url: string): void {
    window.open(url, "_blank");
  }

  public textAreaAdjust(): void {
    let textbox = document.getElementById("description");
    if (textbox) {
      textbox.style.overflow = "hidden";
      textbox.style.height = "1px";
      textbox.style.height = textbox.scrollHeight + "px";
    }
    if (textbox && textbox.scrollHeight > 100) {
      textbox.style.overflow = "auto";
      textbox.classList.add("scroll-style");
    }
  }

  public copyText(val: any): void {
    navigator.clipboard.writeText(val);
    this.toastService.show(ToastType.Success, `Copied Layout URL`, "1%", "40%");
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }

  ngOnDestroy() {
    this.subscribeFormData.unsubscribe();
    this.subscribeMediaData?.unsubscribe(); //unsubscribe
    this.hasChange = true;
    this.locationsHttpService.sendMediaValue([]);
  }
}

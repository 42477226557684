

export const environment = {
  enableFeature:false,


  production: false,

  enableLocations: true,
  
  sso_api_username: '',

  environment:'dev',
  
  sso_api_pwd: '',
  
  baseURL: 'https://api.dev.business.stories.me',

  authURL: 'https://iam-auth.dev.business.stories.me/oauth2/authorize?redirect_uri=https://dev.business.stories.me/admin-console/login&client_id=22bdnk4dekfvav64emo8q8hk50&response_type=code&scope=email+openid&identity_provider=IAMProvider',
  
  loginURL: 'https://iam-auth.dev.business.stories.me/login?' +
    'client_id=22bdnk4dekfvav64emo8q8hk50&response_type=code&scope=email+openid&identity_provider=IAMProvider&' +
    'redirect_uri=https://dev.business.stories.me/admin-console/login',

  redirectURL: 'https://dev.business.stories.me/admin-console/login',

  cognitoTokenURL: 'https://iam-auth.dev.business.stories.me/oauth2/token',
  
  cognitoUserPoolClientId: '22bdnk4dekfvav64emo8q8hk50',
  
  logout: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping?TargetResource=https%3A%2F%2Fdev.business.stories.me%2Flogout',
  
  apiEndPoint: "https://amgmaa-dev.mercedes-benz.com/aca",
  
  apikey: "Ooao5bqcnjUd3MpjtwuOt718kVjFf1YeVY_uhslcRso",

  proxyUrl: "https://proxy.dev.stories.me",
  // proxyUrl: 'http://localhost:4202',
  
  routeBaseURL: "https://dev.stories.me",

  phraseEndPoint: 'https://api.phrase.com/v2/projects',
  phraseUrl: 'https://api.phrase.com/v2/projects/b839b613fcfd932815f81fde21590082/locales/@languageId/translations?access_token=12d65253353cc19d0a84bed6af5cb1d64b20df3121313110d7a0c4159c320dbe', 
  RUM_CONFIG_SESSION_SAMPLE_RATE: 1.0,
  RUM_GUEST_ROLE_ARN: "arn:aws:iam::689157352872:role/Infrastructure-RUMCognito-RumIdentityPoolUnauthent-1EQDBBUULODA7",
  RUM_CONFIG_IDENTITY_POOL_ID: "eu-central-1:377bff03-b230-4116-9e5b-53b1abea0214",
  RUM_ENDPOINT: "https://dataplane.rum.eu-central-1.amazonaws.com",
  RUM_CONFIG_TELEMETRIES: ["errors", "performance", "http"],
  RUM_CONFIG_ALLOW_COOKIES: true,
  RUM_CONFIG_ENABLE_XRAY: true,
  RUM_APPLICATION_ID: "3e7de14e-c60f-448e-8d9f-2a592a1aabab",
  RUM_APPLICATION_REGION: "eu-central-1",
  
  supported_language:['en', 'de', 'en-GB', 'de-DE', 'es-ES', 'fr-FR', 'ko-KR', 'cs-CZ'],
  // amplifyConfig: {
  //   region: 'eu-central-1',
  //   userPoolId: 'eu-central-1_0sMSl5njv',
  //   userPoolWebClientId: '22bdnk4dekfvav64emo8q8hk50',
  //   oauth: {
  //     domain: 'iam-auth.dev.business.stories.me',
  //     scope: ['email', 'openid'],
  //     redirectSignIn: 'http://localhost:4200/login',
  //     redirectSignOut: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping?TargetResource=https%3A%2F%2Fdev.business.stories.me%2Flogout',
  //     responseType: 'code',
  //   },
  // } 
};

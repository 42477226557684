

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    const isAuthenticated = localStorage.getItem('loggedIn');
    const isProfileAlreadySelected=localStorage.getItem("profile")
   //Checking both token and profile selected or not 
    if (isAuthenticated && isProfileAlreadySelected) {
      this.router.navigate(['/events']);
      return false; 
    } else {
      return true;
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";

import {
  Map,
  TileLayer,
} from "leaflet";
import * as L from 'leaflet';
import "@geoman-io/leaflet-geoman-free";
import { CircuitsServicesService } from "../../services/circuits-services.service";
import { Subject } from "rxjs";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { TranslationService } from "src/app/shared/service/translation.service";
import { Router } from "@angular/router";
import { CreateMapService } from "../../services/create-map.service";
let polyline = require("google-polyline");



@Component({
  selector: 'app-circuit-leaflet-map',
  templateUrl: './circuit-leaflet-map.component.html',
  styleUrls: ['./circuit-leaflet-map.component.scss']
})
export class CircuitLeafletMapComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("mapElement")  el!: ElementRef<any>;

  mapInstance: any;
  tileLayer: any;
  @Input() apiKey: any;
  @Input() location: any;
  @Input() radius: any;
  @Input() isZoomControlEnabled: any;
  @Input() zoom: any;
  @Input() isEditCircleFence: any;
  @Input() isShowFence: any;
  @Input() isInteractionEnabled: any = true;
  @Input() selectedOption: any = '';
  @Input() trackData: any;
  @Input() showLayerSwitchMenu: any = true;
  @Input() public isShowCircuitInfo: any = false;
  @Output()
  enableButton = new EventEmitter<any>();
  @Input() radiusValue:any;
  @Output()
  emitNotification = new EventEmitter<any>();
  @Output()
  enableRadiusBar = new EventEmitter<any>();
  @Output() emitRadius = new EventEmitter<any>();

  circleCoordinates: any;
  previousOption: any;

  shapes:any = [];
  geofence: any;
  circle: any;
  polygon: any;
  polyCoordinates: any;
  initialCircle: any;
  drawnItems = L.featureGroup();
  encodedDetails: any;
  circleGeoCoordinates: any;
  myTextLabel: any;
  myCircleTextLabel: any;
  undoArray:any = [];
  redoArray:any = [];
  undoGeofence: any;
  undoCircle: any;
  varRadius:any;
  myTempCircleTextLabel: any;
  progressPolygon: any;
  interactiveRadiusLength: any;
  sessionRadius$:any;
  public storageChange$ = new Subject();
  myFirstCircleTextLabel: any;
  activateUndo: any;
  key: any;
  coordinatesInProgress:any;
  polyline: any;
  enableSatelliteView: boolean = false;
  enableRecenterFeature: boolean = false;
  public url1: any;
  selectedLanguage: string;
  selectedLayer: string = 'normal';
  constructor(private circuitService: CircuitsServicesService, 
    private _translationService : TranslationService, 
    private router : Router,
    private _createMapService : CreateMapService){
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en';
    }
    this.selectedLanguage = selectedLanguage || 'en-GB';
    this.selectedLayer = localStorage.getItem('selectedLayer') || 'normal';
  }


  public ngOnInit() {
    this.enableSatelliteView = this._translationService.featureFlags?.locations.enableSatelliteView;
    this.enableRecenterFeature = this._translationService.featureFlags?.locations.enableRecenter;
    this.url1= this.router.url;
  }


  // wait for view to be rendered, this ensures the div we marked as mapElement will not be null/undefined.
  ngAfterViewInit() {
    this.setupLeaflet();
    if (this.isShowFence) {
      this.plotNonEditableCoordinates();
    } else {
      this.setupGeoman();
    }    
    this.setupEventListeners();
  }

  // This is to capture changes of the tool bar button click

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && !(Array.isArray(this.radius))) {
      this.clearAllLayers();
          this.circleGeoCoordinates = (this.circleGeoCoordinates != undefined && this.circleGeoCoordinates != null) ?this.circleGeoCoordinates:this.circleCoordinates
          let editCircleCoordinates = {
            name: 'circle',
            radius: this.radius * 1000,
            coordinates: this.circleGeoCoordinates
          }
          this.undoArray.push(editCircleCoordinates);
          if(editCircleCoordinates.coordinates != undefined && editCircleCoordinates.coordinates != null) {
            this.createUndoCircle(editCircleCoordinates);
          }

    }
    if(changes && changes.selectedOption) {
      if(changes.selectedOption.currentValue != "" && changes.selectedOption.currentValue != undefined) {
        if(changes.selectedOption.previousValue?.substring(0,4) !== 'clea') {
          this.previousOption = changes.selectedOption.previousValue;
        }        
        this.drawShape(changes.selectedOption.currentValue);
      }
    }
  }

  public switchMapView(): void {
    if (this._createMapService.selectedLayer === 'normal') {
      this.mapInstance.addLayer(this._createMapService.satelliteView);
      this.mapInstance.removeLayer(this._createMapService.normalView);
      this._createMapService.selectedLayer = this.selectedLayer = 'satellite';
    } else if (this._createMapService.selectedLayer === 'satellite') {
      this.mapInstance.addLayer(this._createMapService.normalView);
      this.mapInstance.removeLayer(this._createMapService.satelliteView);
      this._createMapService.selectedLayer = this.selectedLayer = 'normal';
    }
    localStorage.setItem('selectedLayer', this.selectedLayer)
  }

  // Initialize leaflet map, position zoom controls, disable map interaction for edit page

  setupLeaflet() {
    let mapLanguage = this.selectedLanguage.split('-')[0]
    this.circleCoordinates = this.location?.split(",");
    // create leaflet map instanc
    this.mapInstance = new Map(this.el.nativeElement, {
      zoomControl: this.isZoomControlEnabled,
      zoom: this.zoom?this.zoom:11,
      center: this.circleCoordinates
    });
    if(this.isZoomControlEnabled){this.mapInstance.zoomControl.setPosition('bottomright');}

    // create + add tile layer to map
    this._createMapService.normalView = new TileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?lang=${mapLanguage}&lang2=en&apiKey=${this.apiKey}`,
      {
        maxZoom: 19,
        minZoom: 10,
        attribution:
          ''
      });
    this._createMapService.satelliteView = new TileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?style=satellite.day&apiKey=${this.apiKey}`,
      {
        maxZoom: 19,
        minZoom: 10,
        attribution:
          ''
      });
    if (this.selectedLayer === 'satellite') {
      this.mapInstance.addLayer(this._createMapService.satelliteView);
    }
    if (this.selectedLayer === 'normal') {
      this.mapInstance.addLayer(this._createMapService.normalView);
    }

    
    if(!this.isInteractionEnabled) {
      this.mapInstance.dragging.disable();
      this.mapInstance.touchZoom.disable();
      this.mapInstance.doubleClickZoom.disable();
      this.mapInstance.scrollWheelZoom.disable();
      this.mapInstance.boxZoom.disable();
      this.mapInstance.keyboard.disable();
      if (this.mapInstance.tap) this.mapInstance.tap.disable();
    }
  }

  // Initialize colours, plot non editable shapes for edit details page
  setupGeoman() {
      let drawOptions = {
        templineStyle: { color: '#b21e23', },        
        pathOptions: { color: 'transparent', fillColor: '#b21e23', fillOpacity: 0.2 },
    };
    this.mapInstance.pm.setGlobalOptions(drawOptions)    

    if (this.isEditCircleFence) {
      this.setCircleGeoFence();
    }
  }

  // for circuit edit details page only
  plotNonEditableCoordinates() {
    if (this.isShowFence) {
      if(this.radius.length > 1) {
        this.geofence = L.polyline(this.radius, {
          color: "rgba(178, 30, 35, 0.12)",
          fillColor: "rgba(178, 30, 35, 0.12)",
          fill: true,
          fillOpacity: 0.5,
        });
        this.mapInstance.addLayer(this.geofence);
        this.mapInstance.fitBounds(this.geofence.getBounds());
      } else {
        let circle = L.circle(this.mapInstance.getCenter(), {
          radius: this.radius,
          color: 'red',
          weight: 0,
          fillOpacity: 0.2,
        }).addTo(this.mapInstance);
        this.mapInstance.fitBounds(circle.getBounds());
      }     
      
    }
  }

  setupEventListeners() {
    
    //This event adds coordinates for polygon and circle once drawn without edits

    this.mapInstance.on("pm:create", (e: any) => {   
      if (e.shape === "Polygon") {
        this.emitNotification.emit('BP_LM_CREATE_GEO_AREA')
        this.polygon = e.layer;
        let newpolycoordinates: any = []
          let newgeoJson = e.layer._latlngs
          newgeoJson[0].forEach((arra: any) => {
            newpolycoordinates.push([arra['lat'], arra['lng']])
          });
          newpolycoordinates.push([newgeoJson[0][0]['lat'], newgeoJson[0][0]['lng']])
          this.encodedDetails = polyline.encode(newpolycoordinates);              
          this.circuitService.setCoordinateValue(this.encodedDetails);
          this.circuitService.setCoordinatesLocation(newpolycoordinates[0][0] +","+newpolycoordinates[0][1])
          let editPolygonCoordinates = {
            name: 'polygon',
            coordinates: newpolycoordinates
          }
          this.undoArray.push(editPolygonCoordinates)
        e.layer.pm.enable({
          allowSelfIntersection: false,
        });
      }

      if (e.shape === "Circle") {
        this.circle = e.layer;
        this.circle.name = 'circleShape'
        this.circleGeoCoordinates = {
          'radius':( e.layer._mRadius/1000),
          'lat': e.layer._latlng.lat,
          'lng': e.layer._latlng.lng
        }        
        this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
        this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat +","+this.circleGeoCoordinates.lng)
        e.layer.pm.enable({
          allowSelfIntersection: false,
        });
      }

    });

    this.mapInstance.on("pm:drawstart", (e: any) => { 
      if (e.shape === 'Circle') {   
        this.emitRadius.emit(0);     
        this.emitNotification.emit('BP_LM_CENTRE_POINT');
        let circle = e.workingLayer;
        circle.on('pm:centerplaced', (_layer: any): void => {
          this.enableRadiusBar.emit(true);
          this.emitNotification.emit('BP_LM_DRAG_GEO_FENCE');
        })
        circle.on('pm:change', (layer: any): void => {        
          this.emitRadius.emit((layer.layer._mRadius/1000).toPrecision(2));
        })

      }

      if(e.shape === 'Polygon'){
        this.progressPolygon = e.workingLayer;
        this.progressPolygon.on('pm:vertexadded', (layer: any): void => {
          this.coordinatesInProgress = [];
          let latLngs = layer.layer._latlngs
          latLngs.forEach((arra: any) => {
            this.coordinatesInProgress.push([arra['lat'], arra['lng']])
          });
          let polygonCoordinates: any = {
            name: 'polygon',
            coordinates: this.coordinatesInProgress
          }
          this.undoArray.push(polygonCoordinates)
        })
      }

     });

     //This event add coordinates in array for polygon and circle on editing vertexes or radius

    this.mapInstance.on("pm:drawend", (_e: any) => {      
      if (this.polygon) {    

        this.polygon.on('pm:edit', (layer: any): void => {
          this.enableSaveButton();
          let polycoordinates: any = []
          let geoJson = layer.layer._latlngs
          geoJson[0].forEach((arra: any) => {
            polycoordinates.push([arra['lat'], arra['lng']])
          });
          polycoordinates.push([geoJson[0][0]['lat'], geoJson[0][0]['lng']])
          this.encodedDetails = polyline.encode(polycoordinates);              
          this.circuitService.setCoordinateValue(this.encodedDetails);
          this.circuitService.setCoordinatesLocation(polycoordinates[0][0] +","+polycoordinates[0][1])
          let modifiedPolygonCoordinates = {
            name: 'polygon',
            coordinates: polycoordinates
          }
          this.undoArray.push(modifiedPolygonCoordinates)
        })
      }

      
      if (this.circle) {  
        this.emitRadius.emit((this.circle._mRadius/1000).toPrecision(2))
        this.emitNotification.emit(LOCATIONS_CONSTANTS.HELPER_MSGS.DRAG_AND_HOLD_GEOFENCE);
        
        this.circleGeoCoordinates = {
              'radius':(this.circle._mRadius),
              'lat':this.circle._latlng.lat,
              'lng':this.circle._latlng.lng
            }         
        let editCircleCoordinates = {
          name: 'circle',
          radius: this.circleGeoCoordinates.radius,
          coordinates: this.circleGeoCoordinates
        }
        this.clearAllLayers();
        this.createUndoCircle(editCircleCoordinates);

      }

    });

  }


  enableSaveButton() {
    this.activateUndo = true;
    this.enableButton.emit(true);
  }

  public recenterCircuit() {
    this.mapInstance.flyToBounds(this.drawnItems.getBounds());
  }


  // This function plots existing geofence on map and makes it editable
  setCircleGeoFence() {  
    if(!Array.isArray(this.radius)) {
      this.enableRadiusBar.emit(true);
      this.initialCircle = L.circle(this.mapInstance.getCenter(), {
        radius: this.radius,
        color: "rgba(178, 30, 35, 0.12)",
        fillColor: "rgba(178, 30, 35, 0.12)",
        weight: 0,
        fillOpacity: 0.5,
        className: 'leaflet-editing-icon leaflet-div-icon'
      });
      this.drawnItems.addLayer(this.initialCircle);      
      let editCircleCoordinates = {
        name: 'circle',
        radius:this.radius,
        coordinates: this.mapInstance.getCenter()
      }
      let circleValues = {
        lat: this.mapInstance.getCenter().lat,
        lng: this.mapInstance.getCenter().lng,
        radius: this.radius/1000
      }
      this.circuitService.setCoordinateValue(circleValues);
      this.circuitService.setCoordinatesLocation(circleValues.lat +","+circleValues.lng)
      this.undoArray.push(editCircleCoordinates);
          
    }
    
    if(this.radius.length > 1) {
      
      this.geofence = L.polygon(this.radius, {
        color: "rgba(178, 30, 35, 0.12)",
        fillColor: "rgba(178, 30, 35, 0.12)",
        fill: true,
        fillOpacity: 0.5,
      });
      this.drawnItems.addLayer(this.geofence);
      let savePolygonCoordinates = {
        name:'polygon',
        coordinates: this.radius
      }
      let polygonencodedData = this.encodedDetails = polyline.encode(this.radius); 
      this.undoArray.push(savePolygonCoordinates)
      this.circuitService.setCoordinateValue(polygonencodedData);
      this.circuitService.setCoordinatesLocation(this.mapInstance.getCenter().lat +","+this.mapInstance.getCenter().lng);
    }

    this.mapInstance.addLayer(this.drawnItems);
    this.mapInstance.fitBounds(this.drawnItems.getBounds());
    this.drawnItems.pm.enable({
        allowSelfIntersection: false,
      });      
    
    // The below events add coordinates to array to be saved when editing existing geofence
      if (this.geofence) {
        this.geofence.on('pm:edit', (layer: any): void => {
          this.enableSaveButton();
          let geofencecoordinates: any = []
          let geofenceJson = layer.layer._latlngs          
          geofenceJson[0].forEach((arra: any) => {
            geofencecoordinates.push([arra['lat'], arra['lng']])
          });
          geofencecoordinates.push([geofenceJson[0][0]['lat'], geofenceJson[0][0]['lng']])
          this.encodedDetails = polyline.encode(geofencecoordinates);              
          this.circuitService.setCoordinateValue(this.encodedDetails);
          this.circuitService.setCoordinatesLocation(geofencecoordinates[0][0] +","+geofencecoordinates[0][1])
          let editPolygonCoordinates = {
            name:'polygon',
            coordinates: geofencecoordinates
          }
          this.undoArray.push(editPolygonCoordinates)
        })
      }

    if (this.initialCircle) {
      this.initialCircle.on('pm:edit', (layer: any): void => {
        this.enableSaveButton();
        // this.myTextLabel.remove()
        this.circleGeoCoordinates = {
          'radius': (layer.layer._mRadius / 1000),
          'lat': layer.layer._latlng.lat,
          'lng': layer.layer._latlng.lng
        }
        this.emitRadius.emit((layer.layer._mRadius / 1000).toPrecision(2))
        this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
        this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat +","+this.circleGeoCoordinates.lng);
      })
    }
   }

  drawShape(shapeValue: any) {
    let choice = shapeValue.substring(0,4)
    switch(choice) {
      case 'poly':
        this.enableRadiusBar.emit(false);
        this.drawPolyLineShape();
        break;

      case 'clea':
        this.clearAllLayers();
        if(this.previousOption === 'polygon') {
          this.enableRadiusBar.emit(false);
          this.drawPolyLineShape();
        } else if(this.previousOption === 'circle')  {
          this.emitNotification.emit('');
          this.drawCircularShape();
        }
        break;
        
      case 'circ':
        this.drawCircularShape();
        break;

    }
  }

  drawPolyLineShape() {
    this.clearPreviousData();
    this.clearAllLayers();
    this.geofence = null;
    this.enableSaveButton();
    this.undoArray.length = 0;
    this.redoArray.length = 0;
    this.emitNotification.emit('BP_LM_CREATE_GEO_AREA')
    this.mapInstance?.pm.enableDraw('Polygon', {
      snappable: true,
      snapDistance: 20,
      templineStyle:{color: '#b21e23'},
      allowSelfIntersection: false,
      hintlineStyle: { color: '#b21e23',opacity:1, dashArray: [5, 5] },
      shapeOptions: {
        color: '#b21e23',
        stroke: "none"
      }
    });
  }

  drawCircularShape() {
    this.clearPreviousData();
    this.clearAllLayers();
    this.enableSaveButton();
    this.coordinatesInProgress = [];
    this.undoArray.length = 0;
    this.redoArray.length = 0;
    this.mapInstance.pm.enableDraw('Circle', {
      snappable: true,
      snapDistance: 20,
    });
  }

  clearAllLayers() {  
    this.circuitService.setCoordinateValue(null);
    this.circuitService.setCoordinatesLocation(null);
    if (this.mapInstance) {
        this.mapInstance.eachLayer((layer:any)=>{
          if(layer.type?.toLowerCase()==='create'){
            this.mapInstance.removeLayer(layer);
          }
          if(layer.name === "circleShape") {
            this.mapInstance.removeLayer(layer);
          }
        })   
    }
    if(this.polygon) {
      this.polygon.remove();
      this.polygon = null;
    }

    if(this.circle) {
      this.mapInstance.removeLayer(this.circle)
      this.circle.remove();
      this.circle = null;
    }

    if(this.initialCircle) {
      this.initialCircle.remove();
    }

    if(this.geofence) {
      this.geofence.remove();
    }

    if(this.undoGeofence) {
      this.undoGeofence.remove();
    }

    if(this.undoCircle) {
      this.undoCircle.remove();
    }

    if(this.progressPolygon){
      this.progressPolygon.remove();
    }
    this.drawnItems.remove();
    this.circuitService.setCoordinateValue(null);    
  }


  createUndoCircle(data:any) {
    
    this.undoCircle = L.circle(data.coordinates, {
      radius: data.radius,
      color: "rgba(178, 30, 35, 0.12)",
      fillColor: "rgba(178, 30, 35, 0.12)",
      weight: 0,
      fillOpacity: 0.5,
      className: 'leaflet-editing-icon leaflet-div-icon marker-style'
    });    
    
    this.circleGeoCoordinates = {
      'radius': (data.radius / 1000),
      'lat': data.coordinates.lat?data.coordinates.lat:data.coordinates[0],
      'lng': data.coordinates.lng?data.coordinates.lng:data.coordinates[1]
    }
    this.circuitService.setCoordinateValue(this.circleGeoCoordinates);  
    this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat +","+this.circleGeoCoordinates.lng)  
    this.mapInstance.addLayer(this.undoCircle);  
    this.undoCircle.pm.enable({
      allowSelfIntersection: false,
    });    
    
    this.undoCircle.on('pm:change', (layer: any): void => { 
      this.enableSaveButton();        
      this.emitRadius.emit((layer.layer._mRadius/1000).toPrecision(2));
      this.circleGeoCoordinates = {
        radius: layer.layer._mRadius / 1000,
        lat: layer.layer._latlng.lat,
        lng: layer.layer._latlng.lng,
      };
      this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
      this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat +","+this.circleGeoCoordinates.lng)
    });

    this.undoCircle.on("pm:edit", (layer: any): void => {
      this.enableSaveButton(); 
      this.circleGeoCoordinates = {
        radius: layer.layer._mRadius / 1000,
        lat: layer.layer._latlng.lat,
        lng: layer.layer._latlng.lng,
      };
      this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
      this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat +","+this.circleGeoCoordinates.lng)      
    });

  }

  clearPreviousData() {
    this.encodedDetails = null;
    this.circleGeoCoordinates = null;
    this.circleCoordinates = null;
  }
 

  ngOnDestroy() {
    // destroy leaflet instance
    if (this.mapInstance) { this.mapInstance.remove(); }
  }
}